import {SHAPE_VISUALS} from "./model/Constants";
import React from "react";
import * as PropTypes from "prop-types";

export function SelectValuePanel(props) {
    const {selected_value} = props

    return <div className={"select_value_panel"}>
        <div>
            {Array(3).fill(0).map((_, i) => {
                const shape = i;

                function on_value_change(value) {
                    props.value_selected(selected_value.map((v, i) => i === shape ? value + 1 : v))
                }

                return <div style={{display: "flex", alignItems: "center"}}>
                    <div>{SHAPE_VISUALS[shape]}</div>
                    {Array(5).fill(0).map((_, value) =>
                        <button className={"try_button " + (selected_value[shape] === value + 1 ? "highlight" : "")}
                                onClick={() => on_value_change(value)}
                        >
                            {value + 1}
                        </button>)
                    }
                </div>
            })}
        </div>
        <div className={"select_value_validation"}
             style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
            <button onClick={() => props.onSolve()}>Solve !</button>
            <button className={"highlight"} onClick={() => props.onAsk()}>Try Value</button>
        </div>

    </div>
}
SelectValuePanel.propTypes = {onTry: PropTypes.func};
