import games from "../data/games.json"
import {Verifier} from "./verifier";

export class Game {
    static load_games(difficulty, count) {

        return games[difficulty][count].map((data)=>new Game(data))
    }

    static get_random(difficulty, count) {
        const games = this.load_games(difficulty, count);
        return games[Math.floor(Math.random() * games.length)]
    }

    constructor(json_data) {
        this.id = json_data.idPartie
        this.difficulty = json_data.d
        this.code = json_data.code
        this.verifyers = json_data.ind.map((ind_data) => new Verifier(this.code, ind_data))
        const possibilities = this.find_only_possibility()[0]
        for (let i = 0; i < possibilities.length; i++) {
            this.verifyers[i].condition_index = parseInt(possibilities[i])
        }
    }

    find_only_possibility() {
        const verifiers = this.verifyers;
        const code = this.code;
        let combinations = verifiers[0].possibilities.map((v, i) => v.includes(code) ? i.toString() : null).filter(v => v != null)
        for (let i = 1; i < verifiers.length; i++) {
            let newCombination = []
            for (let j = 0; j < verifiers[i].possibilities.length; j++) {
                if (verifiers[i].possibilities[j].includes(code)) {
                    newCombination.push(...combinations.map(c => c + j.toString()))
                }
            }
            combinations = newCombination
        }

        const filter = combinations.filter(
            c => Array.from(c)
                .map((i) => parseInt(i))
                .map((i, v) => verifiers[v].possibilities[i])
                .reduce((a, b) => a.filter(v => b.includes(v))).length === 1);
        if(filter.length===0){
            console.log(this.id)
            console.log(combinations.map(
            c => Array.from(c)
                .map((i) => parseInt(i))
                .map((i, v) => verifiers[v].possibilities[i])
                .reduce((a, b) => a.filter(v => b.includes(v)))))
        }
        return filter
    }

}
