import React from "react";
import {CIRCLE_VISUAL, SQUARE_VISUAL, TRIANGLE_VISUAL} from "./model/Constants";
import * as PropTypes from "prop-types";

function PropositionLine(props) {
    let {proposition} = props
    const EMPTY_RESULTS = [null,null,null,null,null,null];
    if (proposition === null) {
        proposition = [["", "", "",], EMPTY_RESULTS]
    }
    let [value, results] = proposition
    if (results === null) {
        results = EMPTY_RESULTS
    }
    return <React.Fragment>
        {value.map((c) => <div>{c}</div>)}
        {results.map((c) => <div>{c===null?"⬜":(c?"✅":"🟥")}</div>)}
    </React.Fragment>
}

export function PropositionPanel(props) {
    const {propositions, selectedValue,isAsking} = props;

    console.log(5 - propositions.length - (!isAsking?1:0))
    return <div className={"proposition_panel"}>

        <div>{TRIANGLE_VISUAL}</div>
        <div>{SQUARE_VISUAL}</div>
        <div>{CIRCLE_VISUAL}</div>
        <div>A</div>
        <div>B</div>
        <div>C</div>
        <div>D</div>
        <div>E</div>
        <div>F</div>
        {propositions.map((v) => <PropositionLine proposition={v}/>)}
        {!isAsking&&<PropositionLine proposition={[selectedValue, null]}/>}
        {Array(Math.max(0, 5 - propositions.length - (!isAsking?1:0))).fill(0).map((_, i) => <React.Fragment>
            <PropositionLine proposition={null}/>
        </React.Fragment>)}
    </div>
}
PropositionPanel.propTypes = {propositions: PropTypes.arrayOf(PropTypes.any)};
