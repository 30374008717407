import React, {useState} from "react";
import * as PropTypes from "prop-types";
import {CIRCLE_VISUAL, SQUARE_VISUAL, TRIANGLE_VISUAL} from "./model/Constants";
import {PropositionPanel} from "./PropositionPanel";
import {SolvedView} from "./SolvedView";
import {AskQuestionPanel} from "./AskQuestionPanel";
import {SelectValuePanel} from "./SelectValuePanel";
import {CardPannel} from "./CardPannel";

const InitialPossibles = {
    0: [1, 2, 3, 4, 5],
    1: [1, 2, 3, 4, 5],
    2: [1, 2, 3, 4, 5],

}


function PossibleValues(props) {
    const {possibles} = props;
    return <div className={"possible_panel"}>

        <div>{TRIANGLE_VISUAL}</div>
        <div>{SQUARE_VISUAL}</div>
        <div>{CIRCLE_VISUAL}</div>

        {Array(5).fill(1).map((_, i) => {
            return <React.Fragment>
                {Array(3).fill(0).map((_, shape) => {
                    if (possibles[shape].includes(i + 1)) {
                        return <button onClick={()=>props.makeImpossible(shape,i+1)}>{i + 1}</button>
                    }
                    return <button onClick={()=>props.makePossible(shape,i+1)}><del>{i + 1}</del></button>
                })
                }
            </React.Fragment>
        })}
    </div>
}

PossibleValues.propTypes = {
    possibiles: PropTypes.shape({
        1: PropTypes.arrayOf(PropTypes.number),
        2: PropTypes.arrayOf(PropTypes.number),
        3: PropTypes.arrayOf(PropTypes.number)
    })
};


export function GameView(props) {
    const {game} = props

    const [possibles, setPossibles] = useState(InitialPossibles)
    const [propositions, setPropositions] = useState([])
    const [selected_value, setSelectedValue] = useState([null, null, null])
    const [is_asking, setIsAsking] = useState(false)
    const [questionCount, setQuestionCount] = useState(0)
    const [solved, setSolved] = useState(null)
    let onAsk = () => {
        setPropositions(propositions.concat([[selected_value, Array(6).fill(null)]]))
        setIsAsking(true)
        setQuestionCount(3)
    };
    const endAsking = () => {
        setIsAsking(false)
        setSelectedValue([null, null, null])
    }
    let onQuestionAsk = (verifyer_index => {
        const result = game.verifyers[verifyer_index].answer(parseInt(selected_value.join("")))
        setPropositions(propositions.map((v, i) => {
            if (i < propositions.length - 1) return v
            return [v[0], v[1].map((r, i) => i === verifyer_index ? result : r)]
        }))
        propositions[propositions.length - 1][verifyer_index] = result
        if (questionCount === 1) {
            endAsking()
        }
        setQuestionCount(questionCount - 1)
    });
    let onSolve = () => {
        const attempt = parseInt(selected_value.join(""));
        setSolved({attempt: attempt, success: game.code === attempt, solution: game.code})
    }
    let onPass = () => {
        endAsking()
    }

    let makeImpossible =(shape,value)=>{
        if(possibles[shape].includes(value))
            setPossibles({...possibles,[shape]:possibles[shape].filter(v=>v!==value)})
    };
    let makePossible = (shape,value)=>{
        if(!possibles[shape].includes(value)){
            setPossibles({...possibles,[shape]:possibles[shape].concat([value]).sort()})
        }
    }

    return <div className={"game_view"}>
        <div className={"top_panels"}>
            <PropositionPanel propositions={propositions} selectedValue={selected_value} isAsking={is_asking}/>
            <PossibleValues possibles={possibles} makePossible={makePossible} makeImpossible={makeImpossible}/>
        </div>
        <div>
            {/*Insert here note pannel*/}
            <CardPannel verifiers={game.verifyers}/>
        </div>
        <div>
            {!is_asking &&
                <SelectValuePanel selected_value={selected_value} value_selected={setSelectedValue} onAsk={onAsk}
                                  onSolve={onSolve}/>}
            {is_asking && <AskQuestionPanel selected_value={selected_value} onQuestionAsk={onQuestionAsk}
                                            questionCount={questionCount}
                                            proposition={propositions[propositions.length - 1]}
                                            onPass={onPass}
            />}
        </div>
        {solved !== null && <SolvedView retry={props.retry} solved={solved}/>}
    </div>
}