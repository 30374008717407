import logo from './logo.svg';
import './App.css';
import {Game} from "./model/games";
import {GameView} from "./GameView";
import {Header} from "./Header";
import {useState} from "react";
import * as PropTypes from "prop-types";

function ConfigureGame(props) {
    const [difficulty, setDifficulty] = useState("normal")
    const [verifiers, setVerifiers] = useState("5")
    return <div className={"game_configuration"}>
        <h2>Let's Play</h2>
        <div>
            <div>Difficulty</div>
            <button className={difficulty === "easy" ? "highlight" : ""}
                    onClick={() => setDifficulty("easy")}>Easy
            </button>
            <button className={difficulty === "normal" ? "highlight" : ""}
                    onClick={() => setDifficulty("normal")}>Normal
            </button>
            <button className={difficulty === "hard" ? "highlight" : ""}
                    onClick={() => setDifficulty("hard")}>Hard
            </button>
        </div>
        <div>
            <div>Verifiers</div>
            <button className={verifiers === "4" ? "highlight" : ""}
                    onClick={() => setVerifiers("4")}>4
            </button>
            <button className={verifiers === "5" ? "highlight" : ""}
                    onClick={() => setVerifiers("5")}>5
            </button>
            <button className={verifiers === "6" ? "highlight" : ""}
                    onClick={() => setVerifiers("6")}>6
            </button>
        </div>
        <button className={"highlight"} onClick={() => props.onStart(difficulty,verifiers)}>Start</button>
    </div>
    ;
}

ConfigureGame.propTypes = {onStart: PropTypes.func};

function App() {
    const [game, setGame] = useState(null)

    const onRetry = () => {
        setGame(null)
    }
    return (
        <div className="App">
            <Header onRetry={onRetry} game={game}/>
            {game !== null && <GameView key={game.id} game={game} retry={onRetry}/>}
            {game === null && <ConfigureGame onStart={(difficulty,count) => {
                setGame(Game.get_random(difficulty,count))
            }}/>}
        </div>
    );
}

export default App;
