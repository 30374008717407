import {CIRCLE_VISUAL, SQUARE_VISUAL, TRIANGLE_VISUAL} from "./model/Constants";
import React from "react";
import * as PropTypes from "prop-types";

export function SolvedView(props) {
    const {attempt, success, solution} = props.solved
    console.log(props)
    return <div className={"solved_view"}>
        <div>
            {success && <div className={"success"}>
                <h1>Congratulation !</h1>
                <small>You guessed right ! </small>

            </div>}
            {!success && <div>
                <div className={"failed"}>
                    <h1>Sorry !</h1>
                    <small>The solution was:</small>
                </div>
            </div>}
            <div style={{width: "min-content", margin: "auto", display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                <div>{TRIANGLE_VISUAL}</div>
                <div>{SQUARE_VISUAL}</div>
                <div>{CIRCLE_VISUAL}</div>
                {Array.from(solution.toString()).map(v => <span>{v}</span>)}
            </div>
            <button onClick={()=>props.retry()} className={"highlight"} style={{fontSize: "1.5em", padding: "0.3em"}}>Try another</button>
        </div>
    </div>
}
SolvedView.propTypes = {solved: PropTypes.any};