import {C, R, T} from "./Constants.js";
// import possibilities from "../data/verifiers_possibilities.json" assert {type: "json"};
import possibilities from "../data/verifiers_possibilities.json" ;
function get(c, i) {
    return parseInt(c.toString()[i])
}

function getNumbers(c) {
    return Array.from(c.toString()).map((c)=>parseInt(c));
}

function dist(c, a, b) {
    return Math.abs(get(c, a) - get(c, b));
}

const allGrowing = (c) => get(c, T) < get(c, R) && get(c,R) < get(c, C);
const allDecreasing = (c) => get(c, T) > get(c, R) && get(c,R)> get(c, C);
export const CONDITIONS = {
    1: [
        (c) => get(c, T) === 1,
        (c) => get(c, T) > 1,
    ],
    2: [
        (c) => get(c, T) < 3,
        (c) => get(c, T) === 3,
        (c) => get(c, T) > 3,
    ],
    3: [
        (c) => get(c, R) < 3,
        (c) => get(c, R) === 3,
        (c) => get(c, R) > 3,
    ],
    4: [
        (c) => get(c, R) < 4,
        (c) => get(c, R) === 4,
        (c) => get(c, R) > 4,
    ],
    5: [
        (c) => get(c, T) % 2 === 0,
        (c) => get(c, T) % 2 === 1,
    ],
    6: [
        (c) => get(c, R) % 2 === 0,
        (c) => get(c, R) % 2 === 1,
    ],
    7: [
        (c) => get(c, C) % 2 === 0,
        (c) => get(c, C) % 2 === 1,
    ],
    8: [
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 3,
    ],
    9: [
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 3,
    ],
    10: [
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 3,
    ],
    11: [
        (c) => get(c, T) < get(c, R),
        (c) => get(c, T) === get(c, R),
        (c) => get(c, T) > get(c, R),
    ],
    12: [
        (c) => get(c, T) < get(c, C),
        (c) => get(c, T) === get(c, C),
        (c) => get(c, T) > get(c, C),
    ],
    13: [
        (c) => get(c, R) < get(c, C),
        (c) => get(c, R) === get(c, C),
        (c) => get(c, R) > get(c, C),
    ],
    14: [
        (c) => get(c, T) < get(c, C) && get(c, T) < get(c, R),
        (c) => get(c, C) < get(c, T) && get(c, C) < get(c, R),
        (c) => get(c, R) < get(c, C) && get(c, R) < get(c, T),
    ],
    15: [
        (c) => get(c, T) > get(c, C) && get(c, T) > get(c, R),
        (c) => get(c, C) > get(c, T) && get(c, C) > get(c, R),
        (c) => get(c, R) > get(c, C) && get(c, R) > get(c, T),
    ],
    16: [
        (c) => getNumbers(c).filter((i) => i % 2 === 0).length > 1,
        (c) => getNumbers(c).filter((i) => i % 2 === 1).length > 1
    ],
    17: [
        (c) => getNumbers(c).filter((i) => i % 2 === 0).length === 0,
        (c) => getNumbers(c).filter((i) => i % 2 === 0).length === 1,
        (c) => getNumbers(c).filter((i) => i % 2 === 0).length === 2,
        (c) => getNumbers(c).filter((i) => i % 2 === 0).length === 3,
    ],
    18: [
        (c) => getNumbers(c).reduce((a, b) => a + b, 0) % 2 === 0,
        (c) => getNumbers(c).reduce((a, b) => a + b, 0) % 2 === 1
    ],
    19: [
        (c) => get(c, T) + get(c, R) < 6,
        (c) => get(c, T) + get(c, R) === 6,
        (c) => get(c, T) + get(c, R) > 6,
    ],
    20: [
        (c) => (new Set(c.toString()).size - c.toString().length) === -2,
        (c) => (new Set(c.toString()).size - c.toString().length) === -1,
        (c) => (new Set(c.toString()).size - c.toString().length) === 0,
    ],
    21: [
        (c) => (new Set(c.toString()).size - c.toString().length)!==-1,
        (c) => (new Set(c.toString()).size - c.toString().length) === -1,
    ],
    22: [
        (c)=>allGrowing(c),
        (c)=>allDecreasing(c),
        (c) => !(allGrowing(c)) && !(allDecreasing(c)),
    ],
    23: [
        (c) => getNumbers(c).reduce((a, b) => a + b, 0) < 6,
        (c) => getNumbers(c).reduce((a, b) => a + b, 0) === 6,
        (c) => getNumbers(c).reduce((a, b) => a + b, 0) > 6,
    ],
    24: [
        (c) => get(c, T) - get(c, R) === -1 && get(c, R) - get(c, C) === -1,
        (c) => (get(c, T) - get(c, R) === -1 || get(c, R) - get(c, C) === -1) && !(get(c, T) - get(c, R) === -1 && get(c, R) - get(c, C) === -1),
        (c) => !(get(c, T) - get(c, R) === -1) && !(get(c, R) - get(c, C) === -1)
    ],
    25: [
        (c) => dist(c, T, R) !== 1 && dist(c, R, C) !== 1,
        (c) => (dist(c, T, R) === 1 || dist(c, R, C) === 1)&&!(dist(c, T, R) === 1 && dist(c, R, C) === 1 &&dist(c,T,C)===2&& (allGrowing(c) || allDecreasing(c))),
        (c) => dist(c, T, R) === 1 && dist(c, R, C) === 1 && (allGrowing(c) || allDecreasing(c))
    ],
    26: [
        (c) => get(c, T) < 3,
        (c) => get(c, R) < 3,
        (c) => get(c, C) < 3,
    ],
    27: [
        (c) => get(c, T) < 4,
        (c) => get(c, R) < 4,
        (c) => get(c, C) < 4,
    ],
    28: [
        (c) => get(c, T) === 1,
        (c) => get(c, R) === 1,
        (c) => get(c, C) === 1,
    ],
    29: [
        (c) => get(c, T) === 3,
        (c) => get(c, R) === 3,
        (c) => get(c, C) === 3,
    ],
    30: [
        (c) => get(c, T) === 4,
        (c) => get(c, R) === 4,
        (c) => get(c, C) === 4,
    ],
    31: [
        (c) => get(c, T) > 1,
        (c) => get(c, R) > 1,
        (c) => get(c, C) > 1,
    ],
    32: [
        (c) => get(c, T) > 3,
        (c) => get(c, R) > 3,
        (c) => get(c, C) > 3,
    ],
    33: [
        (c) => get(c, T) % 2 === 0,
        (c) => get(c, T) % 2 === 1,
        (c) => get(c, R) % 2 === 0,
        (c) => get(c, R) % 2 === 1,
        (c) => get(c, C) % 2 === 0,
        (c) => get(c, C) % 2 === 1,
    ],
    34: [
        (c) => get(c, T) <= get(c, C) && get(c, T) <= get(c, R),
        (c) => get(c, C) <= get(c, T) && get(c, C) <= get(c, R),
        (c) => get(c, R) <= get(c, C) && get(c, R) <= get(c, T),
    ],
    35: [
        (c) => get(c, T) >= get(c, C) && get(c, T) >= get(c, R),
        (c) => get(c, R) >= get(c, C) && get(c, R) >= get(c, T),
        (c) => get(c, C) >= get(c, T) && get(c, C) >= get(c, R),
    ],
    36:[
        (c)=>getNumbers(c).reduce((a, b) => a + b, 0)%3===0,
        (c)=>getNumbers(c).reduce((a, b) => a + b, 0)%4===0,
        (c)=>getNumbers(c).reduce((a, b) => a + b, 0)%5===0,
    ],
    37: [
        (c) => get(c, T) + get(c, R) ===4,
        (c) => get(c, T) + get(c, C) ===4,
        (c) => get(c, R) + get(c, C) ===4,
    ],
    38: [
        (c) => get(c, T) + get(c, R) ===6,
        (c) => get(c, T) + get(c, C) ===6,
        (c) => get(c, R) + get(c, C) ===6,
    ],
    39: [
        (c) => get(c, T) >1,
        (c) => get(c, T) ===1,
        (c) => get(c, R) >1,
        (c) => get(c, R) ===1,
        (c) => get(c, C) >1,
        (c) => get(c, C) ===1,
    ],
    40: [
        (c) => get(c, T) >3,
        (c) => get(c, T) ===3,
        (c) => get(c, T) <3,
        (c) => get(c, R) >3,
        (c) => get(c, R) ===3,
        (c) => get(c, R) <3,
        (c) => get(c, C) >3,
        (c) => get(c, C) ===3,
        (c) => get(c, C) <3,
    ],
    41: [
        (c) => get(c, T) >4,
        (c) => get(c, T) ===4,
        (c) => get(c, T) <4,
        (c) => get(c, R) >4,
        (c) => get(c, R) ===4,
        (c) => get(c, R) <4,
        (c) => get(c, C) >4,
        (c) => get(c, C) ===4,
        (c) => get(c, C) <4,
    ],
    42: [
        (c) => get(c, T) < get(c, C) && get(c, T) < get(c, R),
        (c) => get(c, C) < get(c, T) && get(c, C) < get(c, R),
        (c) => get(c, R) < get(c, C) && get(c, R) < get(c, T),
        (c) => get(c, T) > get(c, C) && get(c, T) > get(c, R),
        (c) => get(c, C) > get(c, T) && get(c, C) > get(c, R),
        (c) => get(c, R) > get(c, C) && get(c, R) > get(c, T),
    ],
    43: [
        (c) => get(c, T) < get(c, R),
        (c) => get(c, T) === get(c, R),
        (c) => get(c, T) > get(c, R),
        (c) => get(c, T) < get(c, C),
        (c) => get(c, T) === get(c, C),
        (c) => get(c, T) > get(c, C),
    ],
    44: [
        (c) => get(c,R) < get(c, T),
        (c) => get(c,R) === get(c, T),
        (c) => get(c,R) > get(c, T),
        (c) => get(c,R) < get(c, C),
        (c) => get(c,R) === get(c, C),
        (c) => get(c,R) > get(c, C),
    ],
    45: [
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 3,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 3,
    ],
    46: [
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 3,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '3').length === 3,
    ],
    47: [
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '1').length === 3,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 0,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 1,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 2,
        (c) => Array.from(c.toString()).filter(c => c === '4').length === 3,
    ],
    48: [
        (c) => get(c,T) < get(c, R),
        (c) => get(c,T) === get(c, R),
        (c) => get(c,T) > get(c, R),
        (c) => get(c,T) < get(c, C),
        (c) => get(c,T) === get(c, C),
        (c) => get(c,T) > get(c, C),
        (c) => get(c,R) < get(c, C),
        (c) => get(c,R) === get(c, C),
        (c) => get(c,R) > get(c, C),
    ],


}


export class Verifier {

    constructor(code, verifier_id) {
        this.id = verifier_id
        this.code = code
        this.conditions = CONDITIONS[verifier_id]

        this.possibilities = possibilities[verifier_id]
        this.condition_index = null;
    }

    answer(code) {
        return this.conditions[this.condition_index](code)
    }
}