import React from "react";

export function AskQuestionPanel(props) {
    const {onQuestionAsk, questionCount, proposition} = props
    return <div className={"question_panel"}>
        <div className={"question_title"}>
            <div>Value {props.selected_value} Remaining Questions {questionCount}</div>
            <button onClick={() => props.onPass()}>Pass</button>
        </div>

        <div className={"question_grid"}>{Array(6).fill(0).map((_, i) => {

            let className = "question_button";
            if (proposition[1][i] !== null) {
                if (proposition[1][i])
                    className += " true"
                else
                    className += " false"
            }
            return <button disabled={proposition[1][i] !== null} className={className}
                           onClick={() => onQuestionAsk(i)}>{String.fromCharCode(65 + i)}</button>
        })}</div>
    </div>
}
