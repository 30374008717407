export const T = 0;
export const R = 1;
export const C = 2


export const CIRCLE_VISUAL = "🟣";
export const SQUARE_VISUAL = "🟨";
export const TRIANGLE_VISUAL = <img style={{maxHeight:"1em"}} src={"/assets/triangle.svg"}></img>;

export const SHAPE_VISUALS={
    0:TRIANGLE_VISUAL,
    1:SQUARE_VISUAL,
    2:CIRCLE_VISUAL
}