import React, {useCallback, useEffect, useRef, useState} from "react";
import CanvasDraw from "@win11react/react-canvas-draw";
import * as PropTypes from "prop-types";
import {isVisible} from "@testing-library/user-event/dist/utils";

const CARDS = "CARDS"
const NOTES = "NOTES"
const bonjour = "{\"lines\":[{\"points\":[{\"x\":83.34375,\"y\":68.46875},{\"x\":83.34375,\"y\":68.46875},{\"x\":93.34375,\"y\":76.46875},{\"x\":99.34375,\"y\":82.46875},{\"x\":105.34375,\"y\":89.46875},{\"x\":111.34375,\"y\":96.46875},{\"x\":118.34375,\"y\":102.46875},{\"x\":124.34375,\"y\":107.46875},{\"x\":129.34375,\"y\":111.46875},{\"x\":133.34375,\"y\":117.46875},{\"x\":138.34375,\"y\":121.46875},{\"x\":141.34375,\"y\":125.46875},{\"x\":146.34375,\"y\":130.46875},{\"x\":150.34375,\"y\":134.46875},{\"x\":154.34375,\"y\":139.46875},{\"x\":160.34375,\"y\":143.46875},{\"x\":164.34375,\"y\":147.46875},{\"x\":167.34375,\"y\":150.46875},{\"x\":171.34375,\"y\":155.46875},{\"x\":174.34375,\"y\":158.46875},{\"x\":176.34375,\"y\":162.46875},{\"x\":176.34375,\"y\":162.46875}],\"brushColor\":\"#444\",\"brushRadius\":1}],\"width\":354,\"height\":229}"

function Notes(props) {
    const ref = useRef();
    const [data, setData] = useState("")
    const firstRefresh = () => {
        setTimeout(() => {
            console.log("firstRefresh", props.notes)
            if (ref.current === undefined) {
                firstRefresh()
            } else {
                ref.current.loadSaveData(props.notes, true)
            }
        }, 500)

    }
    useEffect(() => {
        console.log(props)
        if (props.notes !== "") {
            firstRefresh()
        }

    }, [props.notes])

    return <div className={"notes"}>
        <CanvasDraw
            ref={ref}
            enablePanAndZoom={true}
            onChange={(e) => {
                props.onNotesChange(e.getSaveData())
            }}
            disabled={props.disabled}
            lazyRadius={0}
            brushRadius={1}
            canvasHeight={props.height}
            canvasWidth={props.width}
            immediateLoading={true}
            backgroundColor={"rgba(255,255,255,0.73)"}/>
    </div>
}

function Card(props) {
    const {verifierId, mode, isFoccused} = props
    const [size, setSize] = useState({cardHeight: 10, cardWidth: 10})
    const image_ref = useRef()

    const update_size = () => {
        const new_sizes = {cardHeight: image_ref?.current?.clientHeight, cardWidth: image_ref?.current?.clientWidth};
        console.log(isFoccused, image_ref?.current)
        if (new_sizes === size) {
            return null;
        }
        setSize(new_sizes)
    }
    useEffect(() => {
        if (verifierId !== null && (size.cardHeight === undefined || size.cardHeight === 10)) {
            setTimeout(() => update_size(), 250)
        }
    }, [size])
    return <div style={{position: "relative", width: "max-content", margin: "auto"}}
                onClick={isFoccused ? undefined : () => props.onFoccused()}>
        {verifierId !== null &&
            <div style={{position: "relative"}}>
                <img className={"card_image"}
                     src={`/cards/fr/TM_GameCards_FR-${verifierId.toString().padStart(2, '0')}.png`}
                     alt="" ref={image_ref}/>
                <div className={"card_name_vignette"}>
                    {String.fromCharCode(65 + props.position)}
                </div>
            </div>
        }
        {verifierId === null && <div className={"card_panel_filler"}></div>}
        {mode === NOTES && <Notes notes={props.notes} onNotesChange={props.onNotesChange} disabled={!isFoccused}
                                  height={size.cardHeight} width={size.cardWidth}/>}
    </div>
}

Card.propTypes = {mode: PropTypes.string};

export function CardPannel(props) {
    const {verifiers} = props;
    const [mode, setMode] = useState(CARDS)
    const [foccused, setFoccused] = useState(null)
    const [notes, setNotes] = useState(["", "", "", "", "", ""])
    const [editedNotes, setEditedNotes] = useState("")
    const updateNotes = (i, data) => {
        if (notes[i] !== data) {
            console.log("notes", data)
            setNotes(notes.map((d, index) => i === index ? data : d))
        }
    }

    function updateEditedNotes(data) {
        setEditedNotes(data)
    }

    function unfoccus() {
        updateNotes(foccused, editedNotes)
        setFoccused(null)
    }

    return <div className={"card_pannel"}>
        <div className={"card_mode_selection"}>
            <button onClick={() => {
                setMode(CARDS)
            }} className={mode === CARDS ? "highlight" : ""}>Cards
            </button>
            <button onClick={() => {
                setMode(NOTES)
            }} className={mode === NOTES ? "highlight" : ""}>Notes
            </button>
        </div>
        <div>
            {foccused === null && <div className={"card_grid"} >
                {Array(6).fill(0).map((_, i) => {
                    return <Card mode={mode} verifierId={i < verifiers.length ? verifiers[i].id : null}
                                 onFoccused={() => setFoccused(i)} isFoccused={foccused === i} notes={notes[i]}
                                 onNotesChange={(data) => {
                                 }} position={i}
                    />
                })}
            </div>}
            {foccused !== null && <div className={"foccused_card"}>
                <Card mode={mode} verifierId={verifiers[foccused].id}
                      onFoccused={() => {
                      }} isFoccused={true} notes={notes[foccused]} onNotesChange={(data) => updateEditedNotes(data)}
                      position={foccused}/>
                <button onClick={() => unfoccus()}>Retour</button>
            </div>}
        </div>

    </div>

}

CardPannel.propTypes = {};
